import { ActionTypes } from '../actionTypes/action-Types'

let initialState = {
  // baseUrl: 'https://evoltion.in/api/',
  // websiteUrl: 'https://evoltion.in/',
  // token: localStorage.getItem('token'),
  // name: localStorage.getItem('name'),
  // email: localStorage.getItem('email'),
  // id: localStorage.getItem('id'),
  fields:localStorage.getItem("fields"),
  fieldData:localStorage.getItem("fieldData")

}

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.LOGIN:
      return { ...state, ...payload }
      

    case ActionTypes.LOGOUT:
      return { ...state, payload }
    case ActionTypes.USER:
      return { ...state, payload }

    default:
      return state
  }
}
