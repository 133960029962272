import { combineReducers } from 'redux'
import { userReducer } from './userReducer'
import { toggleReducer } from './toggleReducer'

const reducers = combineReducers({
  authData: userReducer,
  toggle: toggleReducer,
})

export default reducers
